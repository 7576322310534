import { type NotificationEventDto } from "@hytopia.com/lib/dist/notifications/events";
import { useRouteLoaderData } from "@remix-run/react";
import { createContext, useContext, useEffect, useState } from "react";

import { type loader as rootLoader } from "~/root";
import { hytopiaClient } from "~/utils/hytopiaClient";

const NotificationsContext = createContext<{
  unreadMessageCount: number;
  clearAll: () => Promise<void>;
  markAllAsRead: () => Promise<void>;
  notifications: NotificationEventDto[];
} | null>(null);

export function NotificationsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [notifications, setNotifications] = useState<NotificationEventDto[]>(
    [],
  );
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const rootLoaderData = useRouteLoaderData<typeof rootLoader>("root");

  async function markAllAsRead() {
    try {
      if (!rootLoaderData?.currentUser?.accessToken) {
        return;
      }

      await hytopiaClient.notifications.confirmReads({
        authToken: rootLoaderData.currentUser.accessToken,
        ids: notifications.map((notification) => notification.id),
      });

      setUnreadMessageCount(0);
    } catch (error) {
      console.error(error);
    }
  }

  async function clearAll() {
    try {
      if (!rootLoaderData?.currentUser?.accessToken) {
        return;
      }

      await hytopiaClient.notifications.clear(
        rootLoaderData.currentUser.accessToken,
      );

      setNotifications([]);
      setUnreadMessageCount(0);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (!rootLoaderData?.currentUser) {
      return;
    }

    const socket = hytopiaClient.notifications.createAuthenticatedSocket(
      rootLoaderData.currentUser.accessToken,
    );

    hytopiaClient.notifications
      .retrieve({
        unreadsOnly: false,
        authToken: rootLoaderData.currentUser.accessToken,
      })
      .then((response) => {
        if (response.error) {
          return;
        }

        setNotifications(response.notifications);
        setUnreadMessageCount(
          response.notifications.filter((n) => !n.read).length,
        );
      });

    socket.on("notificationCreated", (notification) => {
      setNotifications((prevNotifications) => [
        notification,
        ...prevNotifications,
      ]);

      setUnreadMessageCount((prevCount) => prevCount + 1);
    });

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootLoaderData?.currentUser]);

  return (
    <NotificationsContext.Provider
      value={{
        clearAll,
        notifications,
        markAllAsRead,
        unreadMessageCount,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}

export function useNotifications() {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error(
      "useNotifications must be used within a NotificationsProvider",
    );
  }

  return context;
}
